import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H3 from '@rotaready/frecl/build/H3';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';
import H1 from '@rotaready/frecl/build/H1';
import H4 from '@rotaready/frecl/build/H4';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const isBrowser = typeof window !== 'undefined';

const Hero = styled.div`
  padding: 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0 100px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row-reverse;
    text-align: left;
    align-items: center;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    max-width: 540px;
    margin-left: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const HeroTextWrapper = styled.div`
  margin-bottom: 20px;
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const BenefitBlock = styled.div`
  padding: 40px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.brand10};
  margin-bottom: 20px;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.bodyTopRef = React.createRef();
  }

  goToForm = () => {
    const scrollTo = this.bodyTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Tenzo integration - Rotaready"
          description="An intelligent integration designed to help hospitality businesses take their reporting to the next level."
          url="integrations/tenzo"
        />

        <ResponsiveContainer>
          <Hero>
            <HeroInnerContainer>
              <HeroBody>
                <HeroTextWrapper>
                  <H1 uistyle="brand160" text="Tenzo and Rotaready" />
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">An intelligent integration designed to help hospitality businesses take their reporting to the next level.</H4>
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">Our free integration with Tenzo gives you visibility over all of your data in one place, enabling you to manage your inventory and staffing levels based on insight from both Rotaready and Tenzo.</H4>
                </HeroTextWrapper>

                <CtaButtons>
                  <Button uistyle="primary" text="Learn more" size="lg" onClick={this.goToForm} />

                  <a href="https://gotenzo.com/upgrade-your-rotaready-reporting" target="_blank" rel="noopener noreferrer">
                    <Button uistyle="primary" text="Visit Tenzo" ghost borderless size="lg" />
                  </a>
                </CtaButtons>
              </HeroBody>

              <HeroIllustration>
                <GatsbyImage
                  image={data.heroImage.childImageSharp.gatsbyImageData}
                  alt="Screenshots of Rotaready and Tenzo"
                  style={{ maxWidth: '450px' }}
                />
              </HeroIllustration>
            </HeroInnerContainer>
          </Hero>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <H3 uistyle="brand160" text="Ramp up your reporting" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="The integration between Tenzo and Rotaready allows labour data from Rotaready to be automatically combined with data from your POS and other systems to power Tenzo's advanced reporting." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Tenzo reads shifts, employees, salaries and signed-off worked hours from Rotaready and feeds this insight into Tenzo. With visibility over all of your data in one place you're able to understand aspects of your business much more clearly and make more informed decisions as a result." />
              </TextBlock>
              <TextBlock>
                <H3 uistyle="brand160" text="Already a Rotaready customer?" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Setting up the integration is easy. Drop us a live chat or an email and our customer success team will guide you through it." />
              </TextBlock>
            </BodyItem>

            <BodyItem innerRef={this.bodyTopRef}>
              <TextBlock>
                <H3 uistyle="brand160" text="About Tenzo" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="An analytics and sales forecasting platform for restaurants, bars and cafes, Tenzo allows you to view all of your data in one place." />
              </TextBlock>

              <BenefitBlock>
                <TextBlock>
                  <UL size="lg" uistyle="normal" iconUistyle="primary">
                    <TextListItem text="Aggregate data across multiple data sources: inventory, labour, point of sale and reviews platforms." />
                    <TextListItem text="Automated email reports and a beautifully designed analytics platform." />
                    <TextListItem text="Lightening fast reporting with insights delivered in real-time to the right person." />
                    <TextListItem text="Take control of your reviews with a social module which allows you to quickly respond to feedback coming from Google Places, Facebook and other platforms." />
                  </UL>
                </TextBlock>
              </BenefitBlock>

              <TextBlock>
                <LinkWithArrow
                  direction="right"
                  text="Find out more about Tenzo"
                  render={props => <a {...props} href="https://gotenzo.com/upgrade-your-rotaready-reporting" target="_blank" rel="noopener noreferrer" />}
                />
              </TextBlock>
            </BodyItem>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "integrations/tenzo/hero-desktop.webp" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }
  }
`;
